import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateBlog } from "../../actions/blogActions";
import { withRouter, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

import { EditorState, convertToRaw , ContentState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class BlogUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        const contentBlock = htmlToDraft(this.props.record.description);
    
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      
        this.state = {
            id: this.props.record.id,
            title: this.props.record.title,
            category: this.props.record.category,
            description: this.props.record.description,
            image: this.props.record.image,
            errors: {},
            editorState
            
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            const contentBlock = htmlToDraft(nextProps.record.description);
    
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
                id: nextProps.record._id,
                title: nextProps.record.title,
                category: nextProps.record.category,
                description: nextProps.record.description,
                image: nextProps.record.image,
                editorState

            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.blog !== undefined
            && nextProps.auth.blog.data !== undefined
            && nextProps.auth.blog.data.message !== undefined
            && nextProps.auth.blog.data.success 
            // && (nextProps.update === undefined || nextProps.update === false)
            ) {
               
            // this.setState({isUpdate:true});    
            $('#update-blog-modal').modal('hide');
            toast(nextProps.auth.blog.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            
            
        }
    }

    onChange = e => {
        if (e.target.id === 'blog-update-title') {
            this.setState({ title: e.target.value });
        }
        if (e.target.id === 'blog-update-category') {
            console.log(e.target.value,'category');
            this.setState({ category: e.target.value });
        }
        if (e.target.id === 'blog-update-description') {
            this.setState({ description: e.target.value });
        }
        if (e.target.id === 'blog-update-image') {
            this.setState({ image: e.target.files[0] });
        }
    };

    onBlogUpdate = e => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("_id",this.state.id);
        formData.append("image",this.state.image);
        formData.append("title", this.state.title);
        formData.append("category", this.state.category);
        formData.append("description", this.state.description);
        this.props.updateBlog(formData);
    };

    onEditorStateChange = (editorState) => {
        
        this.setState({ editorState }, () => {
            
            const contentRaw = convertToRaw(editorState.getCurrentContent());
            const contentHTML = draftToHtml(contentRaw);
        
            this.setState({'description':contentHTML})
        
          });
      };
      
    render() {
        const { errors, editorState } = this.state;
        
        return (
            <div>
                <div className="modal fade" id="update-blog-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Blog</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onBlogUpdate} id="update-blog">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state._id}
                                        id="blog-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                        {(this.state.id !== undefined)?
                                            <Link style={{ float:"right" }} target="_blank" to={'detail?id='+this.state.id}> Preview Page</Link>
                                                :''
                                        }
                                        </div>
                                    </div>    
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="title">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title}
                                                id="blog-update-title"
                                                type="text"
                                                error={errors.title}
                                                className={classnames("form-control", {
                                                    invalid: errors.title
                                                })}/>
                                            <span className="text-danger">{errors.title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="category">Category</label>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <input
                                                onChange={this.onChange}
                                                value={this.state.category}
                                                error={errors.category}
                                                id="blog-update-category"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.category
                                                })}
                                            /> */}
                                            <select 
                                                value={this.state.category}
                                                id="blog-update-category" 
                                                onChange={this.onChange}
                                                className={classnames("form-control", {
                                                    invalid: errors.category
                                                })}
                                                error={errors.category}
                                            >
                                                <option value="62944f2e04a3eb3bbc3876ca">Career</option>
                                                <option value="62944f2e04a3eb3bbc3876cb">Knowledge</option>
                                                <option value="62944f2e04a3eb3bbc3876cc">Technology</option>
                                            </select>
                                            <span className="text-danger">{errors.category}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <input
                                                
                                                onChange={this.onChange}
                                                value={this.state.description}
                                                error={errors.description}
                                                id="blog-update-description"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.description
                                                })}
                                            /> */}
                                            {/* <textarea 
                                                value={this.state.description} 
                                                id="blog-update-description" 
                                                onChange={this.onChange} 
                                                error={errors.description}
                                                className={classnames("form-control", {
                                                    invalid: errors.description
                                                })}
                                            /> */}
                                            <Editor
                                                editorState={editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange}
                                                />
                                            <span className="text-danger">{errors.description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="image">Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                
                                                onChange={this.onChange}
                                                error={errors.description}
                                                id="blog-update-image"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.image
                                                })}
                                            />
                                            <span className="text-danger">{errors.image}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-blog"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update Blog
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BlogUpdateModal.propTypes = {
    updateBlog: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateBlog }
)(withRouter(BlogUpdateModal));
