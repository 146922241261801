/* eslint-disable */
import React, { useState } from "react";
import Header from '../../components/layout/header'
import Footer from '../../components/layout/footer'
import './contact.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Index = () => {
  const [status, setStatus] = useState("Send Message");
  const [fields, setFields] = useState({ "general": ["hire-developer"], "name": "", "email": "", "message": "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    const { name, email, message, general } = fields;
    let details = {
      name: name,
      email: email,
      message: message,
      general: general,

    };
    try {
      let response = await fetch("/api/contact-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });

      if (response.ok) {
        toast.success("Message sent successfully!");
        setFields({ "general": ["hire-developer"], "name": "", "email": "", "message": "" }); // Resetting fields after successful submission
      } else {
        toast.error("Failed to send message. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setStatus("Send Message");
    }
  };

  const _handleChange = (event) => {
    let data = fields;

    if (event.name == 'general') {
      if (event.checked) {
        let general = [...fields.general];
        general.push(event.value)
        data['general'] = general
      } else {
        let general = [...fields.general];
        const index = general.indexOf(event.value);
        if (index > -1) {
          general.splice(index, 1);
        }
        data['general'] = general
      }
    } else {
      data[event.name] = event.value
    }

    setFields({ ...data })
  }

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>Type - Contact Us</title>
        <meta name="description" content="Type - Contact Us" />
        <meta name="keywords" content="Get in touch with our team for web development services and consultation." />

      </Helmet>
      <Header />

      <section className="contactSec secPadding">
        <div className="td-container">
          <div className="td-row">
            <div className="td-col-6">
              <div className="contactForm">
                <h1>Let’s Talk</h1>
                <p>Describe your project and leave us your contact info, we’ll get back to you within 24 hours.</p>
                <div className="formBox">
                  <form onSubmit={handleSubmit}>
                    <div className="formGrp">
                      <label>Your Inquiry</label>
                      <div className="buttonGrp">
                        <div className="checkBox">
                          <input type="checkbox" id="general0" name="general" onChange={(event) => { _handleChange(event.target) }} checked={(fields.general.indexOf('hire-developer') == -1) ? '' : 'checked'} value="hire-developer" defaultChecked={true} />
                          <label htmlFor="general0">Hire a Developer</label>
                        </div>
                        <div className="checkBox">
                          <input type="checkbox" id="general1" name="general" onChange={(event) => { _handleChange(event.target) }} checked={(fields.general.indexOf('general-question') == -1) ? '' : 'checked'} value="general-question" />
                          <label htmlFor="general1">General Questions</label>
                        </div>
                        <div className="checkBox">
                          <input type="checkbox" id="general2" name="general" onChange={(event) => { _handleChange(event.target) }} checked={(fields.general.indexOf('investors') == -1) ? '' : 'checked'} value="investors" />
                          <label htmlFor="general2">Investors</label>
                        </div>
                        <div className="checkBox">
                          <input type="checkbox" id="general3" name="general" onChange={(event) => { _handleChange(event.target) }} checked={(fields.general.indexOf('media-press') == -1) ? '' : 'checked'} value="media-press" />
                          <label htmlFor="general3">Media or Press</label>
                        </div>
                        <div className="checkBox">
                          <input type="checkbox" id="general4" name="general" onChange={(event) => { _handleChange(event.target) }} checked={(fields.general.indexOf('others') == -1) ? '' : 'checked'} value="others" />
                          <label htmlFor="general4">Others</label>
                        </div>
                      </div>
                    </div>
                    <div className="formGrp">
                      <label>Full Name</label>
                      <input type="text" name="name" value={fields.name} onChange={(event) => { _handleChange(event.target) }} placeholder="Enter your full name" required />
                    </div>
                    <div className="formGrp">
                      <label>Email Address</label>
                      <input type="text" name="email" value={fields.email} onChange={(event) => { _handleChange(event.target) }} placeholder="Enter your email address" id="email" />
                    </div>
                    <div className="formGrp">
                      <label>How Can We Help You?</label>
                      <textarea placeholder="I would like to know about" value={fields.message} name="message" onChange={(event) => { _handleChange(event.target) }} id="message" required rows="5"></textarea>
                    </div>
                    <div className="submitBtn">
                      <button className="btn" type="submit">{status}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="td-col-6">
              <div className="rightSec">
                <div className="imageBox">
                  <img src="./images/contact-img.jpg" alt="" />
                </div>
                <div className="contactDetails">
                  <div className="td-row">
                    <div className="td-col-6">
                      <h3>Press</h3>
                      <a href="mailto:press@type.com">media@type-apps.com</a>
                    </div>
                    <div className="td-col-6">
                      <h3>Customer Support</h3>
                      <a href="mailto:support@type.com">hello@type-apps.com</a>
                    </div>
                    <div className="td-col-6">
                      <h3>Address</h3>
                      <p>Type Apps<br></br>
                        6060 Silver Dr, 3rd Floor<br></br>
                        Burnaby BC<br></br>
                        V5H 0H5
                      </p>
                    </div>
                    {/* <div className="td-col-6">
                      <h3>Work Hours</h3>
                      <p>Monday - Friday</p>
                      <p>9AM - 5PM (PST)</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials secPadding text-center">
        <div className="td-container">
          <OwlCarousel items={1}
            className="owl-theme testiSlider"
            loop
            nav
            autoplay={true}
            margin={8} >

            <div className="item">
              <div className="slideContent">
                <p>The freelance talent we work with are more productive than we ever thought possible. My relationship with Cara & Kaori keeps on growing. The projects get larger and more technical every year.</p>
                <div className="profile">
                  <img src="./images/avatar.jpg" alt="" />
                  <h3>Hugh Pitchwon</h3>
                  <p>Head of Manager, Epsilon Inc.</p>
                </div>
                <div className="stars">
                  <img src="./images/star.svg" alt="" />
                  <img src="./images/star.svg" alt="" />
                  <img src="./images/star.svg" alt="" />
                  <img src="./images/star.svg" alt="" />
                  <img src="./images/star.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="slideContent">
                <p>The freelance talent we work with are more productive than we ever thought possible. My relationship with Cara & Kaori keeps on growing. The projects get larger and more technical every year.</p>
                <div className="profile">
                  <img src="./images/avatar.jpg" alt="" />
                  <h3>Hugh Pitchwon</h3>
                  <p>Head of Manager, Epsilon Inc.</p>
                </div>
                <div className="stars">
                  <img src="./images/star.svg" alt="" />
                  <img src="./images/star.svg" alt="" />
                  <img src="./images/star.svg" alt="" />
                  <img src="./images/star.svg" alt="" />
                  <img src="./images/star.svg" alt="" />
                </div>
              </div>
            </div>

          </OwlCarousel>
          {/* <div className="controls">
            <span className="prevBtn"><img src="./images/arrow-left.svg" alt="" /></span>
            <span className="nextBtn"><img src="./images/arrow-right.svg" alt="" /></span>
          </div> */}
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Index