export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const USER_ADD = "USER_ADD";
export const USER_UPDATE = "USER_UPDATE";

export const CMS_ADD = "CMS_ADD";
export const CMS_UPDATE = "CMS_UPDATE"

export const BLOG_ADD = "BLOG_ADD";
export const BLOG_UPDATE = "BLOG_UPDATE"
