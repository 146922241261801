import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addBlog } from "../../actions/blogActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class BLogAddModal extends React.Component {

    constructor(props) {
        
        super();
        this.state = {
            title: "",
            description: "",
            category: "",
            image: "",
            errors: {},
            editorState: EditorState.createEmpty(),
            
        };
    }

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.blog !== undefined
            && nextProps.auth.blog.data !== undefined
            && nextProps.auth.blog.data.message !== undefined ) {
                // this.setState({isAdded:true});
            $('#add-blog-modal').modal('hide');
            toast(nextProps.auth.blog.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            
        }
    }

    onChange = e => {
        if(e.target.id == 'image'){
            console.log(e.target.id,e.target.files);
            this.setState({ [e.target.id]: e.target.files[0] }); 
        }else{
            this.setState({ [e.target.id]: e.target.value });
        }
    };

    onBlogAdd = e => {
        e.preventDefault();
       
        const formData = new FormData();
        formData.append("image",this.state.image);
        formData.append("title", this.state.title);
        formData.append("category", this.state.category);
        formData.append("description", this.state.description);
        
        this.props.addBlog(formData, this.props.history);
    };

    onEditorStateChange = (editorState) => {
        
        this.setState({ editorState }, () => {
            
            const contentRaw = convertToRaw(editorState.getCurrentContent());
            const contentHTML = draftToHtml(contentRaw);
        
            this.setState({'description':contentHTML})
        
          });
      };
    render() {
        const { errors, editorState } = this.state;
     
        return (
            <div>
                <div className="modal fade" id="add-blog-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Blog</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onBlogAdd} id="add-blog">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title}
                                                id="title"
                                                type="text"
                                                error={errors.title}
                                                className={classnames("form-control", {
                                                    invalid: errors.title
                                                 })}/>
                                            <span className="text-danger">{errors.title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="category">Category</label>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <input
                                                onChange={this.onChange}
                                                value={this.state.category}
                                                error={errors.category}
                                                id="category"
                                                type="category"
                                                className={classnames("form-control", {
                                                    invalid: errors.category
                                                })}
                                            /> */}
                                            <select 
                                                value={this.state.category}
                                                id="category" 
                                                onChange={this.onChange}
                                                className={classnames("form-control", {
                                                    invalid: errors.category
                                                })}
                                                error={errors.category}
                                            >
                                                <option value="62944f2e04a3eb3bbc3876ca">Career</option>
                                                <option value="62944f2e04a3eb3bbc3876cb">Knowledge</option>
                                                <option value="62944f2e04a3eb3bbc3876cc">Technology</option>
                                            </select>
                                            <span className="text-danger">{errors.category}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="description">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                           
                                            {/* <textarea 
                                                value={this.state.description} 
                                                id="description" 
                                                onChange={this.onChange} 
                                                error={errors.description}
                                                className={classnames("form-control", {
                                                    invalid: errors.description
                                                })}
                                            /> */}
                                            <Editor
                                                value={this.state.description} 
                                                editorState={editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange}
                                                id="description" 
                                            />
                                            <span className="text-danger">{errors.description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="file">Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                               
                                                error={errors.image}
                                                id="image"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.image
                                                })}
                                            />
                                            <span className="text-danger">{errors.image}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-blog"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add Blog
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BLogAddModal.propTypes = {
    addBlog: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addBlog }
)(withRouter(BLogAddModal));
