import axios from "axios";
import {
    GET_ERRORS,
    CMS_ADD,
    CMS_UPDATE
} from "./types";

export const addCms = (cmsData, history) => dispatch => {
    axios
        .post("/api/page-add", cmsData)
        .then(res =>
            dispatch({
                type: CMS_ADD,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateCms = (cmsData) => dispatch => {
    axios
        .post("/api/page-update", cmsData)
        .then(res =>
            dispatch({
                type: CMS_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
