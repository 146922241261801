import {
    SET_CURRENT_USER,
    USER_ADD,
    USER_LOADING,
    USER_UPDATE,
    CMS_UPDATE,
    CMS_ADD,
    BLOG_ADD,
    BLOG_UPDATE

} from "../actions/types";
const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
};
export default function(state = initialState, action) {
    switch (action.type) {
        case USER_ADD:
            return {
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case CMS_ADD:
                return {
                    isAuthenticated: !isEmpty(action.payload),
                    user: action.payload
                };
            
        case USER_UPDATE:
            return {
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            };
            
        case CMS_UPDATE:
            return {
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case USER_LOADING:
            return {
                ...state,
                loading: true
            };

        case BLOG_ADD:
                return {
                    ...state,
                    isAuthenticated: !isEmpty(action.payload),
                    blog: action.payload
                };
             
        case BLOG_UPDATE:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                blog: action.payload,
            }; 
                       
        default:
            return state;
    }
}
