import React from 'react';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import './services.css'; // Ensure this CSS file is created

const Services = () => {
    return (
        <>
            <Header/>
            <section className="servicesSec secPadding">
                <div className="td-container">
                    <div className="mainContent text-center">
                        <h2 className="serviceTitle">Our Specialized Web Development Services</h2>
                        <p className="serviceDescription">From initial consultation to final deployment, we offer a complete suite of web development services tailored to your needs.</p>
                    </div>
                    <div className="servicesList">
                        <div className="serviceItem">
                            <h3>Custom Web & Mobile Application Development</h3>
                            <p>Responsive web applications and mobile apps for iOS and Android, including publishing to Apple and Google Play app stores.</p>
                        </div>
                        <div className="serviceItem">
                            <h3>E-commerce Solutions</h3>
                            <p>Robust e-commerce platforms using Shopify, WooCommerce, tailored for your business. Building and customizing CMS solutions like WordPress for content flexibility.</p>
                        </div>
                        <div className="serviceItem">
                            <h3>API Development & Integration</h3>
                            <p>Custom API development and integration with popular APIs as per business needs.</p>
                        </div>
                        <div className="serviceItem">
                            <h3>Cloud Services and Deployment</h3>
                            <p>Expert cloud solutions for app deployment, ensuring scalability and security.</p>
                        </div>
                        <div className="serviceItem">
                            <h3>Digital Marketing (SEO)</h3>
                            <p>
                                Search Engine Optimization (SEO) services to improve your website’s visibility and ranking on Google and other search engines.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default Services;
