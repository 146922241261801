import React, { useState } from "react";
import { Link } from "react-router-dom";
import {isMobile} from 'react-device-detect';

function Header() {
    const [show, setShow] = useState(false);
    if(isMobile) {
  return (      
    <header className="siteHeader">
        
        <div className="td-row">
            <div className="td-col">
                <div className="logoBox">
                <Link to={'/'} ><img src="images/logo.png" alt="" /></Link>
                </div>
            </div>
            <div className="td-col">
                <div id="mainMenu" className="menuBox" style={{ opacity: !show ? "0" : "1",  transition: "all 2s", visibility: !show ? "hidden" : "visible" }}>
                    <div id="closeMenu" style={{display: "none"}} onClick={() => setShow((s) => !s)}><span>X</span></div>
                    <ul className="menu">
                    {/* <li className="menuLink"><Link to={'why-type'}>Why Type</Link></li> */}
                    <li className="menuLink"><Link to={'services'}>Our Services</Link></li>
                    {/* <li className="menuLink"><Link to={'talent'}>Talent</Link></li> */}
                    {/* <li className="menuLink"><Link to={'blog'} >Blog</Link></li> */}
                    </ul>
                </div>
            </div>
            <div className="td-col">
                <div className="btnBox">
                    <Link to={'/contact'} className="btn">Contact Us</Link>
                </div>
                {/* <div id="mobileMenu" style={{display: "none"}} onClick={() => setShow((s) => !s)}>
                    <svg viewBox="0 0 100 80" width="25" height="25">
                      <rect width="100" height="10"></rect>
                      <rect y="30" width="100" height="10"></rect>
                      <rect y="60" width="100" height="10"></rect>
                    </svg>
                </div> */}
            </div>
        </div>
    
</header>
  )
    }
    else
    {
        return (      
            <header className="siteHeader">
                
            <div className="td-container">
                <div className="td-row">
                    <div className="td-col">
                        <div className="logoBox">
                        <Link to={'/'} ><img src="./images/logo.png" alt="" /></Link>
                        </div>
                    </div>
                    <div className="td-col">
                        <div id="mainMenu" className="menuBox">
                            <div id="closeMenu" style={{display: "none"}}><span>X</span></div>
                            <ul className="menu">
                            {/* <li className="menuLink"><Link to={'why-type'}>Why Type</Link></li> */}
                            <li className="menuLink"><Link to={'services'}>Our Services</Link></li>
                            {/* <li className="menuLink"><Link to={'talent'}>Talent</Link></li> */}
                            {/* <li className="menuLink"><Link to={'/blog'} >Blog</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="td-col">
                        <div className="btnBox">
                            <Link to={'contact'} className="btn">Contact Us</Link>
                        </div>
                        <div id="mobileMenu" style={{display: "none"}}>
                            <svg viewBox="0 0 100 80" width="25" height="25">
                              <rect width="100" height="10"></rect>
                              <rect y="30" width="100" height="10"></rect>
                              <rect y="60" width="100" height="10"></rect>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            
        </header>
        
        
          )


    }
}

export default Header