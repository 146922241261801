import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import React, { Component } from 'react';
import Login from "./components/auth/Login";
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/popper.js/dist/popper';

import User from "./components/pages/Users";
import Cms from "./components/pages/Cms";
import Home from "./containers/Home";
import Contact from "./containers/Contact";
import Blog from "./containers/Blog";
import Detail from "./containers/Detail";
import WhyType from "./containers/Cms/WhyType";
import HireDeveloper from "./containers/Cms/HireDeveloper";
import Company from "./containers/Cms/Company";
// import Talent from "./containers/Cms/Talent";
import OurProcess from "./containers/Cms/OurProcess";
import BookCall from "./containers/Cms/BookCall";
import HowItWork from "./containers/Cms/HowItWork";
import Howtohire from "./containers/Cms/Howtohire";
// import Contact from  "./containers/Contact/Contactus"
import Services from "./containers/Services";
import Blogs from "./components/pages/Blog";

import ReactGA from 'react-ga';
const TRACKING_ID = "G-D6WM8E7KN0"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

if (localStorage.jwtToken) {
    const token = localStorage.jwtToken;
    setAuthToken(token);
    const decoded = jwt_decode(token);
    store.dispatch(setCurrentUser(decoded));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.href = "./login";
    }
}

class App extends Component {
    render () {
        return (
            <Provider store={store}>
                <Router>
                    {/* <div className="App"> */}
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/why-type" component={WhyType} />

                            <Route exact path="/register" component={Register} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/blog" component={Blog} />
                            <Route exact path="/detail" component={Detail} />
                            <Route exact path="/hiredeveloper" component={HireDeveloper} />
                            <Route exact path="/bookcall" component={BookCall} />
                            <Route exact path="/company" component={Company} />
                            <Route exact path="/howitwork" component={HowItWork} />
                            {/* <Route exact path="/talent" component={Talent} /> */}
                            <Route exact path="/services" component={Services} />
                            <Route exact path="/howtohire" component={Howtohire} />

                            

                            <Switch>
                                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                                <PrivateRoute exact path="/users" component={User} />
                                <PrivateRoute exact path="/cms" component={Cms} />
                                <PrivateRoute exact path="/blogs" component={Blogs} />
                                

                            </Switch>
                            <Route exact path="*" component={NotFound} />
                        </Switch>
                    {/* </div> */}
                </Router>
            </Provider>
        );
    }
}

export default App;
