import React,{ useState, useEffect} from 'react'
import Header from '../../components/layout/header'
import Footer from '../../components/layout/footer'
import { Link     } from "react-router-dom";
import './blog.css';
import Post from './Post';
import {Helmet} from "react-helmet";
const postsPerPage = 3;
let arrayForHoldingPosts = [];


const Blog = () => {

    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(3);
    const [page, setPage] = useState(1);
    const [blogs, setBlog] = useState([]);
    const [recentblogs, setRecentBlog] = useState([]);
    const [ meta , setMeta ] = useState({})




    useEffect(  () => {
        
        getData(page,0)
        getMetaData()
        
      }, []);

    const getMetaData = async () => {
        var page = "blog";
        let response = await fetch(`/api/page-meta?page=${page}`, {
            method: "GET",
          });
        
        let result = await response.json();
        setMeta({ meta_title:result.meta_title,meta_description:result.meta_description,meta_keyword:result.meta_keyword}) 
    }  
    
    const loopWithSlice = (start, end) => {
       
        const slicedPosts = blogs.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(blogs);
        
        
    };
    
    const handleShowMorePosts = () => {
        setPage(page+1) 
        getData(page+ 1,next)
        // loopWithSlice(next, next + postsPerPage);
        // setNext(next + postsPerPage);
        
    };

    const getData = async (limit,a) => {
        // e.preventDefault();
        let response = await fetch(`/api/blog-list?page=${limit}`, {
            method: "GET"
          });
        
        let result = await response.json();
        let data = blogs;
        data.push(...result.blog);
        setBlog([...data])    
        setRecentBlog(result.recent)   
        loopWithSlice(a, postsPerPage);
    }
  return (
    <>
        <Helmet>
            <title>{meta?.meta_title}</title>
            <meta name="description" content={meta?.meta_description} />
            <meta name="keywords" content={meta?.meta_keyword} />
        </Helmet>
        <Header/>
        <section className="blogSec secPadding">
            <div className="td-container">
                <div className="mainContent text-center">
                    <div className="tag"><span><img src="./images/blog-icon.svg" alt="" /> Blogs</span></div>
                    <h2>Read some amazing blog writen by us</h2>
                    <p>Discover resources, guides and articles that can help you build unique solutions. Visit our learning centre for heaps of content.</p>
                </div>
                <div className="blogBoxes">
                    <div className="td-row">
                        <div className="td-col-7">
                            <Post blogData={postsToShow} />
                            
                        </div>
                        <div className="td-col-5">
                            {recentblogs.map((data, index)=>( 
                            <div className="smallBlog">
                                <div className="blogImg">
                                <Link to={`/detail?id=${data._id}`} >
                                    <img src={ "/blog_image/"+data.image } alt="" />
                                    </Link>
                                </div>
                                <div className="blogContent">
                                    <div className="metaData"><span className="tag">{ data.categoryname } </span> <span className="date">9 Hours Ago</span></div>
                                    <Link to={`/detail?id=${data._id}`} ><h3 className='smallTitle'>{ data.title }</h3></Link>
                                    <p className='smallDesc' dangerouslySetInnerHTML={{__html:data.description}} ></p>
                                </div>
                            </div>
                            )) }
                            
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <button type='button' onClick={handleShowMorePosts} className="btn">Show More</button>
                </div>
            </div>
        </section>            
        <Footer/>
    </>
  )
}

export default Blog