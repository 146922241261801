import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCms } from "../../actions/cmsActions";
import { withRouter,Link } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

const pageList = { "home":"home","services":"services","why-type":"why-type","blog":"blog","talent":"talent","contact":"contact" }
class CmsUpdateModal extends React.Component {

    constructor(props) {
        super(props);
       
        this.state = {
        id: this.props.record.id,
        title: this.props.record.title,
        content: this.props.record.content,
        status: this.props.record.status,
        alias: this.props.record.alias,
        meta_title:this.props.record.meta_title,
        meta_description:this.props.record.meta_description,
        errors: {},
        meta_keyword:this.props.record.meta_keyword,
        page_link : pageList[this.props.record.alias]};

        this.onPageUpdate = this.onPageUpdate.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }
    
    handleChange(e) {
        this.setState({
            content: e.target.value
        })
        }
    componentWillReceiveProps(nextProps) {
        // console.log(nextProps);
        if (nextProps.record) {
            console.log('pageList',pageList[nextProps.record.alias],nextProps.record.alias);
            this.setState({
                id: nextProps.record.id,
                title: nextProps.record.title,
                alias: nextProps.record.alias,
                content: nextProps.record.content,
                status: nextProps.record.status,
                meta_title: nextProps.record.meta_title,
                meta_description: nextProps.record.meta_description,
                meta_keyword: nextProps.record.meta_keyword,
                page_link:pageList[nextProps.record.alias]
                
            })
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {
            $('#update-page-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    onChange = e => {
        console.log(e.target)
        if (e.target.id === 'page-update-title') {
            this.setState({ title: e.target.value });
        }
        if (e.target.id === 'page-update-alias') {
            this.setState({ alias: e.target.value });
        }
        if (e.target.id === 'page-update-status') {
            this.setState({ status: e.target.value });
        }
        if (e.target.id === 'page-update-meta_title') {
            this.setState({ meta_title: e.target.value });
        }
        if (e.target.id === 'page-update-meta_keywordkk') {
            this.setState({ meta_keyword: e.target.value });
        }
        if (e.target.id === 'page-update-meta_descriptionpp') {
            this.setState({ meta_description: e.target.value });
        }
        console.log(this.state.meta_description)
    };
    onPageUpdate = e => {
        e.preventDefault();
        const newPage = {
            _id: this.state.id,
            title: this.state.title,
            content: this.state.content,
            status: this.state.status,
            alias: this.state.alias,
            meta_title: this.state.meta_title,
            meta_description: this.state.meta_description,
            meta_keyword: this.state.meta_keyword
        };
        this.props.updateCms(newPage,this.props.history);
    };

    render() {
        const { errors } = this.state;
        console.log(this.state.page_link,'wwwww');
        return (
            <div>
                <div className="modal fade" id="update-page-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Page</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onPageUpdate} id="update-page">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="page-update-id"
                                        type="text"
                                        className="d-none" />
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input onChange={this.onChange}
                                                value={this.state.title}
                                                id="page-update-title"
                                                type="text"
                                                error={errors.title}
                                                className={classnames("form-control", {
                                                    invalid: errors.title
                                                })} />
                                            <span className="text-danger">{errors.title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="alias">Alias</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.alias}
                                                error={errors.alias}
                                                id="page-update-alias"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.alias
                                                })}
                                            />
                                            <span className="text-danger">{errors.alias}</span>
                                            {(this.state.page_link !== undefined)?
                                            <Link target="_blank" to={this.state.page_link}> Page</Link>
                                                :''
                                            }
                                            
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="content">Content</label>
                                        </div>
                                        <div className="col-md-9">
                                        <textarea value={this.state.content} id="page-update-content" onChange={this.handleChange} />
                                            
                                            <span className="text-danger">{errors.content}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="alias">Status</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select value={this.state.status} id="page-update-status" onChange={this.onChange}>
                                                <option value="1">Enable</option>
                                                <option value="0">disable</option>
                                            </select>
                                            <span className="text-danger">{errors.status}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="meta_title">Meta Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input onChange={this.onChange}
                                                value={this.state.meta_title}
                                                error={errors.meta_title}
                                                id="page-update-meta_title"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.meta_title
                                                })}
                                            />
                                            <span className="text-danger">{errors.meta_title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="meta_description">Meta Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input onChange={this.onChange}
                                                value={this.state.meta_description}
                                                id="page-update-meta_descriptionpp"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.meta_description
                                                })}
                                            />
                                            <span className="text-danger">{errors.meta_description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="meta_keyword">Meta Keyword</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input onChange={this.onChange}
                                                value={this.state.meta_keyword}
                                                id="page-update-meta_keywordkk"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.meta_keyword
                                                })}
                                            />
                                            <span className="text-danger">{errors.meta_keyword}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-page"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update Page
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CmsUpdateModal.propTypes = {
    updateCms: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateCms }
)(withRouter(CmsUpdateModal));
