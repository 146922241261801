import axios from "axios";
import {
    GET_ERRORS,
    BLOG_ADD,
    BLOG_UPDATE
} from "./types";



export const addBlog = (blogData, history) => dispatch => {
 
    axios
        .post("/api/blog-add", blogData)
        .then(res =>
            dispatch({
                type: BLOG_ADD,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        })
    );
};


export const updateBlog = (blogData) => dispatch => {
    axios
        .post("/api/blog-update", blogData)
        .then(res =>
            dispatch({
                type: BLOG_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
