import React from 'react'
import { Link } from 'react-router-dom';
import './blog.css';
const  Post = (props) => {
    const { blogData } = props;
    // console.log('blogData',blogData);
  return (

        <>
        { blogData.map((blogData, index) => (
        <div key={index} className="bigBlog">
            
            <div className="blogImg">
                <Link to={`/detail?id=${blogData._id}`} >
                    <img src={ '/blog_image/'+blogData.image } alt="" />
                </Link>
            </div>
            <div className="blogContent">
                <div className="metaData"><span className="tag">{ blogData.categoryname }</span> <span className="date">Feb 04</span></div>
                <Link to={`/detail?id=${blogData._id}`}><h3>{ blogData.title }</h3></Link>
                <p className='elip' dangerouslySetInnerHTML={{__html:blogData.description}}></p>
            </div>
            
        </div>
        ))}
        </>
  )
}

export default Post