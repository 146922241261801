import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addCms } from "../../actions/cmsActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

class CmsAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            title: "",
            content: "",
            status: "1",
            alias: "",
            meta_title: "",
            meta_description: "",
            meta_keyword: "",
            errors: {},
             rawMessage:''
        };
        this.handleChange = this.handleChange.bind(this);

    }
   
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log(nextProps);
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-cms-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    handleChange(e) {
        this.setState({
            content: e.target.value
        })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onCmsAdd = e => {
        e.preventDefault();
        //console.log(this.state);
        const newCms = {
            title: this.state.title,
            content: this.state.content,
            status: this.state.status,
            alias: this.state.alias,
            meta_title: this.state.meta_title,
            meta_description: this.state.meta_description,
            meta_keyword: this.state.meta_keyword
        };
        this.props.addCms(newCms, this.props.history);
    };

    render() {
        const { errors } = this.state;
        const { editorState } = this.state;
        const wrapperStyle = {
            border: '1px solid #969696',
        }
        const editorStyle = {
            height:'10rem',
            padding:'1rem'
        }
        return (
            <div>
                <div className="modal fade" id="add-cms-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Cms page</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCmsAdd} id="add-cms">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="title">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.title}
                                                id="title"
                                                type="text"
                                                error={errors.title}
                                                className={classnames("form-control", {
                                                    invalid: errors.title
                                                })}/>
                                            <span className="text-danger">{errors.title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="alias">Alias</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.alias}
                                                error={errors.alias}
                                                id="alias"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.alias
                                                })}
                                            />
                                            <span className="text-danger">{errors.alias}</span>
                                        </div>
                                    </div>
                                    
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                        <label htmlFor="content">Content</label>
                                        </div>
                                        <div className="col-md-9">
                                        <div style={{marginTop:'5%'}}> 
                            <textarea value={this.state.content} id="page-update-content" onChange={this.handleChange} />
</div>
                                            <span className="text-danger">{errors.content}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="alias">Status</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select value={this.state.status} id="status" onChange={this.onChange}>
                                        <option value="1">Enable</option>
                                        <option value="0">disable</option>
                                        </select>
                                            <span className="text-danger">{errors.status}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="meta_title">Meta Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.meta_title}
                                                error={errors.meta_title}
                                                id="meta_title"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.meta_title
                                                })}
                                            />
                                            <span className="text-danger">{errors.meta_title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="meta_description">Meta Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.meta_description}
                                                id="meta_description"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.meta_description
                                                })}
                                            />
                                            <span className="text-danger">{errors.meta_description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="meta_keyword">Meta Keyword</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.meta_keyword}
                                                id="meta_keyword"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.meta_keyword
                                                })}
                                            />
                                            <span className="text-danger">{errors.meta_keyword}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-cms"
                                    type="submit"
                                    onClick={this.handleEditorStateToMessage}

                                    className="btn btn-primary">
                                    Add Cms page
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CmsAddModal.propTypes = {
    addCms: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addCms }
)(withRouter(CmsAddModal));
